import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
import { userAction } from "../../store/user-slice";

const Super = () => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const dispatch = useDispatch();
  const list = apis();

  useEffect(() => {
    const getAuth = async () => {
      const data = {
        url: list.UserIsAuth,
        method: "POST",
        token: localStorage.getItem("accessToken"),
      };

      const result = await dispatch(httpAction(data));

      if (result?.status) {
        setAuth(true);
        dispatch(
          userAction.setUser({
            name: result?.user?.name,
            email: result?.user?.email,
            role: result?.user?.role,
          })
        );
        dispatch(userAction.setContacts(result?.contacts));
      } else {
        setAuth(false);
      }
      setLoading(false);
    };
    getAuth();
  }, [dispatch, list]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!auth) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default Super;
