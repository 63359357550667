import React, { useEffect, useState } from "react";
import "./contacts.css";
import ContactTabs from "./ContactTabs";
import AddNewContact from "./AddNewContact";
import { IoPersonAddOutline } from "react-icons/io5";
import { HiMenuAlt1 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
import { IoReaderOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Contacts = () => {
  const [openContactForm, setOpenContactForm] = useState(false);
  const [openSidebar, setOpenSideBar] = useState(false);
  const [role, setRole] = useState();

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const list = apis();
  const openContactFormHandler = () => {
    setOpenContactForm(true);
  };

  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, []);

  const sideBarOpenHandler = async () => {
    setOpenSideBar(true);

    const data = {
      url: list.getAllUser,
      method: "GET",
      token: localStorage.getItem("accessToken"),
    };
    const result = await dispatch(httpAction(data));
    if (result?.status) {
      setUsers(result?.list);
    }
  };

  const sideBarCloseHandler = () => {
    setOpenSideBar(false);
  };

  const closeContactFormHandler = () => {
    setOpenContactForm(false);
  };

  const loginHandler = async (item) => {
    const email = item.email;
    const password = item.pin;
    const data = {
      url: list.userLogin,
      method: "POST",
      body: { email, password },
    };
    const result = await dispatch(httpAction(data));
    if (result?.status) {
      localStorage.setItem("accessToken", result?.token?.accessToken);
      setOpenSideBar(false);
      navigate("/contacts");
      window.location.reload();
    }
  };

  return (
    <div className="contact_main">
      <div className={openSidebar ? "sidebar_show" : "side_bar"}>
        <div className="side_bar_header">
          <IoClose onClick={sideBarCloseHandler} className="close_icon" />
        </div>
        <div>
          {users?.map((item, index) => {
            return (
              <div
                onClick={() => loginHandler(item)}
                key={index}
                className="user_report_container"
              >
                <span className="user_report_name">{item.name}</span>
                <IoReaderOutline className="report_icon" />
              </div>
            );
          })}
        </div>
      </div>
      <AddNewContact
        open={openContactForm}
        hideHandler={closeContactFormHandler}
      />
      <div>
        <ContactTabs />
      </div>
      <div className="add_new_contact_flot">
        <IoPersonAddOutline
          onClick={openContactFormHandler}
          className="contact_icon"
        />
        {role === "admin" && (
          <HiMenuAlt1 onClick={sideBarOpenHandler} className="menu_icon" />
        )}
      </div>
    </div>
  );
};

export default Contacts;
