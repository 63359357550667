import React, { useEffect, useState } from "react";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
import LoadingBacdrop from "../ui/LoadingBacdrop";
import SingleContact from "./SingleContact";
import './contactList.css'
const ContactList = ({ category, dateFilter }) => {
  const [contacts, setContacts] = useState([]);
  const dispatch = useDispatch();
  const list = apis();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getContacts = async () => {
      const data = {
        url: list.getUserContact + "?category=" + category,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      };
      setContacts([]);
      setLoading(true);
      const result = await dispatch(httpAction(data));
      setLoading(false);
      if (result?.status) {
        setContacts(result?.list);
      }
    };

    const getDailedReport = async () => {
      const data = {
        url: list.getUserCallReport + "?dateFilter=" + dateFilter,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      };
      setLoading(true);
      const result = await dispatch(httpAction(data));
      setLoading(false);
      if (result?.status) {
        setContacts(result?.list);
      }
    };

    if (category === "dailed") {
      getDailedReport();
    } else {
      getContacts();
    }
  }, [category, dateFilter]);

  return (
    <div className="contact_list_main">
      <LoadingBacdrop open={loading} handleClose={() => setLoading(false)} />
      {category === "dailed" && <div  className="dailed_count" >Count: {contacts?.length}</div>}
      <div>
        {contacts?.map((item, index) => (
          <SingleContact
            key={index}
            index={index}
            item={item}
            contacts={contacts}
            setContacts={setContacts}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactList;
