import React from "react";
import "./header.css";
import { useSelector } from "react-redux";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="header_main">
      <span className="login_user_name">{user?.name}</span>
      <span className="total_contacts" > Total Contacts: {user?.totalContacts}</span>
      <div onClick={logoutHandler} className="header_action">
        <span>Logout</span>
        <IoIosLogOut className="logout" />
      </div>
    </div>
  );
};

export default Header;
