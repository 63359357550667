import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Input from "../ui/Input";
import Button from "../ui/Button";
import "./addNewContact.css";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
const AddNewContact = ({ open, hideHandler }) => {
  const dispatch = useDispatch();
  const list = apis();

  const [num, setNum] = useState();
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState("other");

  const sourceChange = (title) => {
    setSource(title);
  };

  const numChange = (event) => {
    setNum(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const data = {
      url: list.addNewContact,
      method: "POST",
      token: localStorage.getItem("accessToken"),
      body: { number: num, source: source },
    };
    setLoading(true);
    const result = await dispatch(httpAction(data));
    setLoading(false);
    if (result?.status) {
      toast.success(result?.message);
      setNum("");
    }
  };

  return (
    <Modal centered show={open} onHide={hideHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={submitHandler}>
          <div className="contact_cource_container">
            <label>Select Source</label>
            <div className="contact_source_items">
              <span
                style={{
                  backgroundColor: source === "facebook" && "seagreen",
                  color: source === "facebook" && "white",
                }}
                onClick={() => sourceChange("facebook")}
              >
                Facebook
              </span>
              <span
                style={{
                  backgroundColor: source === "other" && "seagreen",
                  color: source === "other" && "white",
                }}
                onClick={() => sourceChange("other")}
              >
                Other
              </span>
            </div>
          </div>
          <div className="add_contact_form">
            <Input
              required
              type="number"
              placeholder="type  number here"
              onChange={numChange}
              value={num}
            />
            <Button disabled={loading} type="submit">
              {loading ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewContact;
