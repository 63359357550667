import React, { useEffect, useState } from "react";
import "./singleContact.css";
import { IoCall } from "react-icons/io5";
import { MdHistoryToggleOff } from "react-icons/md";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { TfiFacebook } from "react-icons/tfi";
import { Link } from "react-router-dom";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { formatDistanceStrict } from "date-fns";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { LuMessageSquare } from "react-icons/lu";
import tabItems from "../../utils/tabItems";
import Button from "../ui/Button";
import { BsFire } from "react-icons/bs";
import toast from "react-hot-toast";
const SingleContact = ({ item, index, contacts, setContacts }) => {
  const [isDail, setIsDail] = useState(false);
  const [category, setCategory] = useState(item?.category);
  const dispatch = useDispatch();
  const list = apis();
  const [openHis, setOpenHis] = useState(false);
  const [feedback, setFeedback] = useState();
  const tabs = tabItems();
  useEffect(() => {
    setFeedback(item?.feedback);
  }, [item]);
  const feedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const openHisHandler = () => {
    if (item?.lastDail === null) {
      return;
    }
    setOpenHis(true);
  };

  const isDailHandler = async () => {
    setIsDail(true);
  };

  const addHistory = async () => {
    const data = {
      url: list.userAddCallHis,
      method: "POST",
      body: { numId: item?._id },
      token: localStorage.getItem("accessToken"),
    };

    const result = await dispatch(httpAction(data));
    if (result?.status) {
      const prevNumber = [...contacts];
      prevNumber.splice(index, 1, result?.number);
      setContacts(prevNumber);
      setIsDail(true);
    }
  };

  const last =
    item?.lastDail !== null
      ? formatDistanceStrict(new Date(item?.lastDail), new Date()) + " " + "ago"
      : "Not dialed yet";

  const changeCatHandler = (cat) => {
    setCategory(cat);
  };

  const feedbackSubmitHamdler = async (event) => {
    event.preventDefault();

    const data = {
      url: list.addContactFeedback + "?numId=" + item?._id,
      method: "POST",
      token: localStorage.getItem("accessToken"),
      body: { feedback, category },
    };

    const result = await dispatch(httpAction(data));
    if (result?.status) {
      toast.success(result?.message);
      setIsDail(false);
      const prevContact = [...contacts];
      prevContact.splice(index, 1, result?.number);
      setContacts(prevContact);
    }
  };

  return (
    <div className="single_contact_box">
      <Modal centered show={isDail} onHide={() => setIsDail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={feedbackSubmitHamdler}>
            <div className="single_contact_feedback_container">
              <textarea
                onChange={feedbackChange}
                value={feedback}
                rows={5}
                placeholder="type here..."
                className="contact_feedback"
                required
              />

              <div className="single_contact_options">
                {tabs
                  .filter((item) => item !== "dailed")
                  .map((cat, index) => {
                    return (
                      <span
                        onClick={() => changeCatHandler(cat)}
                        style={{
                          backgroundColor: cat === category && "seagreen",
                          color: cat === category && "white",
                        }}
                        key={index}
                      >
                        {cat}
                      </span>
                    );
                  })}
              </div>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal centered show={openHis} onHide={() => setOpenHis(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Call History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="single_contact_history">
            {item?.callHis
              ?.sort((a, b) => {
                return new Date(b) - new Date(a);
              })
              .map((item, index) => {
                const history =
                  item !== null
                    ? formatDistanceStrict(new Date(item), new Date()) +
                      " " +
                      "ago"
                    : "no history found";
                return (
                  <div key={index}>
                    <span>{history}</span>
                    <span>{new Date(item).toLocaleString()}</span>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
      <div className="single_contact_main">
        <div className="snglr_contact_name_container">
          <div className="single_contact_source">
            <span className="single_contact_num">0{item?.number}</span>
            {item?.source === "facebook" && (
              <TfiFacebook className="facebook_icon" />
            )}
          </div>
          <BsFire
            style={{
              display: item?.category === "interested" ? "block" : "none",
            }}
            className="fire_icon"
          />
        </div>
        <div className="single_contact_icons">
          <Link
            target="_blank"
            to={`tel:0${item?.number}`}
            onClick={addHistory}
          >
            <IoCall className="icon_whatsapp" />
          </Link>
          <Link
            target="_blank"
            to={`https://wa.me/+92${item?.number}`}
            onClick={addHistory}
          >
            <FaWhatsapp className="icon_call" />
          </Link>
          <Link onClick={isDailHandler}>
            <LuMessageSquare />
          </Link>
          <Link onClick={openHisHandler}>
            <MdHistoryToggleOff className="icon_his" />
          </Link>
        </div>
      </div>
      <div className="single_contact_detail">
        <span className="single_contact_last">
          Last call:{" "}
          <span
            style={{ color: last === "Not dialed yet" ? "red" : "seagreen" }}
          >
            {last}
          </span>
        </span>
        <p>{item?.feedback}</p>
      </div>
    </div>
  );
};

export default SingleContact;
