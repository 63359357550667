import React, { useState } from "react";
import "./login.css";
import Input from "../ui/Input";
import Button from "../ui/Button";
import { Link, useNavigate } from "react-router-dom";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
const Register = () => {
  const list = apis();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const emailChange = (event) => {
    setEmail(event.target.value);
  };
  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const nameChange = (event) => {
    setName(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const registerData = {
      url: list.userRegister,
      method: "POST",
      body: { name: name, email: email, password: password },
    };
    setLoading(true);
    const result = await dispatch(httpAction(registerData));
    setLoading(false);

    if (result?.status) {
      navigate("/login");
    }
  };

  return (
    <div className="login_main">
      <div className="login_header"></div>
      <form onSubmit={submitHandler}>
        <div className="login_container">
          <Input
            type="text"
            placeholder="Enter your name"
            onChange={nameChange}
            value={name}
            required
          />
          <Input
            type="email"
            placeholder="Enter your email"
            onChange={emailChange}
            value={email}
            required
          />
          <Input
            type="password"
            placeholder="Enter your password"
            onChange={passwordChange}
            value={password}
            required
          />
          <Button disabled={loading} type="submit">
            {loading ? "Please Wait..." : "Register"}
          </Button>
          <div className="login_links">
            <Link to="/login">Already have an account?</Link>
            {/* <Link className="link_password_forget" to="/password/forgot">
              Forget password?
            </Link> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
