import React, { useState } from "react";
import "./login.css";
import Input from "../ui/Input";
import Button from "../ui/Button";
import { Link, useNavigate } from "react-router-dom";
import httpAction from "../../store/httpAction";
import apis from "../../store/apis";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = apis();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const emailChange = (event) => {
    setEmail(event.target.value);
  };
  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const dataToLogin = {
      url: list.userLogin,
      method: "POST",
      body: { email, password },
    };
    setLoading(true);
    const result = await dispatch(httpAction(dataToLogin));
    setLoading(false);
    if (result?.status) {
      toast.success(result?.message);
      localStorage.setItem("accessToken", result?.token?.accessToken);
      localStorage.setItem("refreshToken", result?.token?.refreshToken);
      localStorage.setItem("role", result?.role);
      navigate("/contacts");
    }
  };

  return (
    <div className="login_main">
      <div className="login_header"></div>
      <form onSubmit={submitHandler}>
        <div className="login_container">
          <Input
            type="email"
            placeholder="Enter your email"
            onChange={emailChange}
            value={email}
            required
          />
          <Input
            type="password"
            placeholder="Enter your password"
            onChange={passwordChange}
            value={password}
            required
          />
          <Button disabled={loading} type="submit">
            {loading ? "Please Wait..." : "Login"}
          </Button>
          <div className="login_links">
            <Link to="/register">Don't have an account?</Link>
            <Link className="link_password_forget">Forget password?</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
