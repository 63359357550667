const apis = () => {
  const local = "http://localhost:8081/";
  const live = "https://marketing.hafizbrothersestate.com/";
  return {
    userLogin: `${live}user/login`,
    userRegister: `${live}user/register`,
    addNewContact: `${live}user/contact/add`,
    getUserContact: `${live}user/contact/get`,
    userAddCallHis: `${live}user/callHis/add`,
    addContactFeedback: `${live}user/feedback/add`,
    UserIsAuth: `${live}user/auth`,
    getUserCallReport: `${live}user/report/call`,
    getAllUser: `${live}user/get/list`,
  };
};

export default apis;
