import React from "react";
import { Routes, Route } from "react-router-dom";
import Register from "./cmponents/account/Register";
import Login from "./cmponents/account/Login";
import Contacts from "./cmponents/contacts/Contacts";
import Layout from "./cmponents/layout/Layout";
import Super from "./cmponents/layout/Super";
const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route element={<Super />}>
        <Route path="/contacts" element={<Layout />}>
          <Route path="/contacts" element={<Contacts />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
