import React, { useState } from "react";
import "./contactTab.css";
import tabItems from "../../utils/tabItems";
import ContactList from "./ContactList";
const ContactTabs = () => {
  const tabs = tabItems();
  const [tab, setTab] = useState("default");
  const [dateFilter, setDateFilter] = useState("today");

  const tabChange = (item) => {
    setTab(item);
  };

  return (
    <div className="contact_tab_main">
      <div className="contact_tab_container">
        {tabs.map((item, index) => {
          return (
            <span
              onClick={() => tabChange(item)}
              style={{
                backgroundColor: item === tab && "black",
                color: item === tab && "white",
              }}
              className="single_tab"
              key={index}
            >
              {item}
            </span>
          );
        })}
      </div>
      {tab === "dailed" && (
        <div className="contact_dailed_tabs">
          {dailedFilter().map((item, index) => {
            return (
              <span
                onClick={() => setDateFilter(item)}
                style={{
                  backgroundColor: item === dateFilter && "seagreen",
                  color: item === dateFilter && "white",
                  borderColor: item === dateFilter && "white",
                }}
                className="dailed_tab"
                key={index}
              >
                {item}
              </span>
            );
          })}
        </div>
      )}
      <ContactList dateFilter={dateFilter} category={tab} />
    </div>
  );
};

export default ContactTabs;
export function dailedFilter() {
  return ["today", "yesterday", "last 7 days"];
}
