import React from "react";
import "./input.css";
const Input = ({ placeholder, type, onChange, value, required }) => {
  return (
    <input
      className="ui_input"
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      required={required}
    />
  );
};

export default Input;
