import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { name: "", email: "", role: "", totalContacts: 0 },
  reducers: {
    setUser(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.role = action.payload.role;
    },
    setContacts(state, action) {
      state.totalContacts = action.payload;
    },
  },
});

export const userAction = userSlice.actions;
export default userSlice;
